//import 'datatables.net-dt/css/jquery.dataTables.css';
import DataTable from 'datatables.net-dt';

$(document).ready(() => {

    $('#sz_devis-nb').text(sz_devis.nbProducts);
    $('.sz_devis_count').text(sz_devis.nbProducts);

    $('body').on('click', '.js-sz_devis-add', function (event) {
        let self = this;
        prestashop.emit('clickSz_devisistAdd', {
            dataset: self.dataset,
            self: self
        });
        event.preventDefault();
    });

    $('body').on('click', '.js-sz_devis-remove', function (event) {

        let self = this;
        prestashop.emit('clickSz_devisRemove', {
            dataset: self.dataset
        });
        event.preventDefault();
    });


    prestashop.on('clickSz_devisistAdd', function (elm) {

        if($('.js-sz-devis-qty-'+elm.dataset.idProduct).length > 1) {
            var qtyValue = $('.js-sz-devis-qty-'+elm.dataset.idProduct).eq(1).val();
        } else {
            var qtyValue = $('.js-sz-devis-qty-'+elm.dataset.idProduct).val();
        }

        //let qtyValue = $('.js-sz-devis-qty-'+elm.dataset.idProduct).val();
        let qtyProduct = qtyValue ? qtyValue : 1;

        let data = {
            'process': 'add',
            'ajax': 1,
            'idProduct': elm.dataset.idProduct,
            'idProductAttribute': elm.dataset.idProductAttribute,
            'qtyProduct': qtyProduct,
            'token': elm.dataset.token,
        };

        $.post(elm.dataset.url, data, null, 'json').then(function (resp) {

            if (!resp.success && resp.data.type == 'notLogged'){
                let quickModal = $('.modal.quickview').first();

                if (quickModal.length){
                    $(quickModal).modal('hide');
                    $(quickModal).on('hidden.bs.modal', function (e) {
                        $('#sz_devis-modal').modal('show');
                    });
                } else{
                    $('#sz_devis-modal').modal('show');
                }
                return;
            }
            $(elm.self).addClass('sz_devis-added');
            if (resp.success){
                sz_devis.nbProducts++;
                $('#sz_devis-nb').text(sz_devis.nbProducts);
                $('.sz_devis_count').text(sz_devis.nbProducts);

                let $notification =  $('#sz_devis-notification');
                $notification.addClass('ns-show');

                setTimeout(function(){
                    $notification.removeClass('ns-show');
                }, 3500);
            }

        }).fail((resp) => {
            prestashop.emit('handleError', {eventType: 'clickSz_devisAdd', resp: resp});
        });

    });

    prestashop.on('clickSz_devisRemove', function (elm) {

        

        let data = {
            'process': 'remove',
            'ajax': 1,
            'idProduct': elm.dataset.idProduct,
            'token': elm.dataset.token,
        };


        $.post(elm.dataset.url, data, null, 'json').then(function (resp) {
            console.log('#isz_devis-product-' + elm.dataset.idProduct);
            $('#sz_devis-product-' + elm.dataset.idProduct).remove();
            sz_devis.nbProducts--;
            $('#sz_devis-nb').text(sz_devis.nbProducts);
            $('.sz_devis_count').text(sz_devis.nbProducts);

            if (sz_devis.nbProducts == 0) {
                $('#sz_devis-warning').removeClass('hidden-xs-up');
                $('#sz_devis-crosseling, #sz_devis-share').addClass('hidden-xs-up');
            }

        }).fail((resp) => { console.log(resp);
            prestashop.emit('handleError', {eventType: 'clickSz_devisRemove', resp: resp});
        });

    });

    prestashop.on('updatedProduct', function (elm) {
        $('#sz-devis-product-btn').data('id-product-attribute', elm.id_product_attribute);
        $('#sz-devis-product-btn').attr('data-id-product-attribute', elm.id_product_attribute);
    });


    $('#sz_devis-clipboard-btn').on('click', function () {

        var $btn = $(this);

        var copyInput = $btn.closest('.input-group').children('input.js-to-clipboard');
        copyInput.select();

        try {
            var successful = document.execCommand('copy');
            if (successful) {
                $btn.text($btn.data('textCopied'));
                setTimeout(function(){ $btn.text($btn.data('textCopy'));  }, 1500);
            }
        } catch (err) {
            console.log('Oops, unable to copy');
        }
    });

    // add cart 

    $('input[name="includeCartToQuote"]').change(function (e) {
        checkIncludeCartToQuote(e);
    });

    function checkIncludeCartToQuote(e) {
        var includeCartToQuoteValue = $('input[name="includeCartToQuote"]:checked').val();


         if (includeCartToQuoteValue == 1) {

            let selectedCart = e.target;
            var html_line = "<table class='table table-sm table-hover'>";

            let data = {
                'process': 'getcart',
                'ajax': 1,
                'token': selectedCart.dataset.token,
            };

            $.post(selectedCart.dataset.url, data, null, 'json').then(function (resp) { 

                if(resp.data.nbProducts > 0) {
                    
                    resp.data.cart.forEach(line => {
                        console.log(line);
                        html_line+= "<tr class='data-lead' data-leadref='"+line['reference']+"' data-leadqty='"+line['quantity']+"' data-leadInstock='1'><td>"+line['reference']+"</td><td>"+line['name']+"</td><td>x"+line['quantity']+"</td></tr>"
                    });

                    $('.js-includeCartToQuote').html(html_line+"</table>");
                }

            }).fail((resp) => {
                console.log(resp);
            });
   
             $('.js-includeCartToQuote').html(html_line);
         } else {
             
             $('.js-includeCartToQuote').empty();
         }
    }

    // textarea comm

          checkIncludeMsgToQuote();

     $('input[name="includeMsgToQuote"]').change(function (event) {        

         checkIncludeMsgToQuote();
     });

     function checkIncludeMsgToQuote() {

         var includeMsgToQuoteValue = $('input[name="includeMsgToQuote"]:checked').val();


         if (includeMsgToQuoteValue == 1) {

   
             $('.js-includeMsgToQuote').html('<label for="exampleFormControlTextarea1" class="mt-3">Message</label><textarea class="form-control" name="additionalDetailsForQuote" required></textarea>');
         
            } else {
             
             $('.js-includeMsgToQuote').empty();
         
            }
     }

     // send request

     $('body').on('click', '.js-sz-devis-send-quote', function (event) {
        
        

        let self = this;

        prestashop.emit('clickSz_devisisendlead', {
            dataset: self.dataset,
            self: self
        });
        event.preventDefault();

     });

     prestashop.on('clickSz_devisisendlead', function (elm) { 

        const elementsWithDataLead = $('.data-lead');
        const leadDetailsArray = [];
        elementsWithDataLead.each(function () {
            const leadRef = $(this).data('leadref');
            const leadQty = $(this).data('leadqty');
            const leadinstock = $(this).data('leadinstock');

            // Construire un objet JSON avec les valeurs récupérées
            const leadDetails = {
                ref: leadRef,
                qty: leadQty,
                inStock: leadinstock,
                delivery_time : 0
            };

            // Ajouter l'objet JSON au tableau
            leadDetailsArray.push(leadDetails);
        });

        let data = {
            'process': 'addlead',
            'ajax': 1,
            'token': elm.dataset.token,
            'dataProducts' : JSON.stringify(leadDetailsArray),
            'message_ad': $('[name="additionalDetailsForQuote"]').length > 0 ? $('[name="additionalDetailsForQuote"]').val() : '',
        };        

        $.post(elm.dataset.url, data, null, 'json').then(function (resp) { 

            console.log(resp);
            if(resp.data.cleaned) {
                $('.sz-devis-bloc-view-front').addClass('d-none');

                let $notification =  $('.sz-devis-added-true');
                $notification.removeClass('d-none');

                // show succès modal
                $('#successModal-sz_devis').modal('show');
            }

        }).fail((resp) => {
            console.log("fail"); 
            console.log(resp.responseText);
        });

     });

     $('#product div.col-add-qty input[name=qty]').addClass('js-sz-devis-qty-' + $('input[id=product_page_product_id]').val());

     if(sz_devis.nbProducts > 0) {
     $('#_desktop_blockcart-content ul.cart-products').append(sz_devis.notificationCart);
     } 

     if(parseInt(sz_devis.nbRequestHistory) >  0) {
        
        let table = new DataTable('#sz_devis_history', {
            searching: true,
            language: {
                info: 'Showing page _PAGE_ of _PAGES_',
                infoEmpty: 'No records available',
                infoFiltered: '(filtered from _MAX_ total records)',
                lengthMenu: 'Display _MENU_ records per page',
                zeroRecords: 'Nothing found - sorry'
            }
        });
     }

});


